import { SecuredLayout } from '../Layout/SecuredLayout';
import { Grid, Stack, Typography, CircularProgress } from '@mui/material';
import { StyledDetailsCard, StyledSendNotificationCard } from '../Layout/styles';
import SendNotification from './SendNotification';
import { EmployeeDetails } from '../../@types/employeeData';
import { useEffect, useState } from 'react';
import { useAuth, useProvideSnackBar } from '../../hooks';
import { DataGridPro, GridColDef, getGridDateOperators, getGridStringOperators, huHU } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import huLocale from 'date-fns/locale/hu';
import ErrorBoundary from '../../hooks/errorBoundary';

const API_ENDPOINT = (window as any).WORKER_TRANSPORT_API_URL;

const columns: GridColDef[] = [
    {
        field: 'workplace',
        headerName: 'Munkahely',
        width: 230,
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        renderHeader: () => <strong>Munkahely</strong>,
        filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
    },
    {
        field: 'operationUnit',
        headerName: 'Üzemegység',
        width: 230,
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        renderHeader: () => <strong>Üzemegység</strong>,
        filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
    },
    {
        field: 'line',
        headerName: 'Járat',
        width: 230,
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        renderHeader: () => <strong>Járat</strong>,
        filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
    },
    {
        field: 'stop',
        headerName: 'Megálló',
        width: 230,
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        renderHeader: () => <strong>Megálló</strong>,
        filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
    },
    {
        field: 'employeeName',
        headerName: 'Dolgozó neve',
        width: 230,
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        renderHeader: () => <strong>Dolgozó neve</strong>,
        filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
    },
    {
        field: 'employeeID',
        headerName: 'Dolgozó azonosító',
        width: 200,
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        renderHeader: () => <strong>Dolgozó azonosító</strong>,
        filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
    },
    {
        field: 'arrivalTime',
        headerName: 'Megállóba érkezés ideje',
        width: 230,
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        type: 'dateTime',
        headerClassName: 'headerName',
        renderHeader: () => <strong>Megállóba érkezés ideje</strong>,
        filterOperators: getGridDateOperators().filter(({ value }) => ['onOrAfter', 'onOrBefore'].includes(value)),
        valueFormatter: params => {
            return format(new Date(params.value as string), 'yyyy.MM.dd HH:mm:ss');
        },
    },
    {
        field: 'language',
        headerName: 'Nyelv',
        width: 80,
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        renderHeader: () => <strong>Nyelv</strong>,
        filterOperators: getGridStringOperators().filter(({ value }) => ['contains'].includes(value)),
    },
];

export default function WorkerTravelDetailsPage() {
    const { user } = useAuth();
    const { showError, showResponseError } = useProvideSnackBar();
    const [employeeData, setEmployeeData] = useState<EmployeeDetails[]>([]);
    const [registrationTokens, setRegistrationTokens] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchGetWorkerTravelDetails = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_ENDPOINT}/v1/app-user/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });
            if (response.ok) {
                const existingDetails: EmployeeDetails[] = await response.json();
                setEmployeeData(existingDetails);
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError('Hiba a szerver elérésében! ' + error.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchGetWorkerTravelDetails();
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={huLocale}>
            <SecuredLayout>
                <Grid container px={3} pt={1} sx={{ justifyContent: 'center' }}>
                    <StyledSendNotificationCard>
                        <Grid item xs={12} mb={3} mt={2}>
                            <SendNotification registrationTokens={registrationTokens} setRegistrationTokens={setRegistrationTokens} />
                        </Grid>
                    </StyledSendNotificationCard>
                    <StyledDetailsCard>
                        {employeeData.length > 0 ? (
                            <Stack sx={{ height: 'calc(100vh - 350px)', width: '100%', mt: 1 }}>
                                <ErrorBoundary>
                                    <DataGridPro
                                        density={'compact'}
                                        columns={columns}
                                        rows={employeeData.map(employee => ({
                                            id: employee.id + ' ' + employee.deviceToken,
                                            workplace: employee.workplace,
                                            operationUnit: employee.operationUnit,
                                            line: employee.line,
                                            stop: employee.stop,
                                            employeeName: employee.workerName,
                                            employeeID: employee.workerId,
                                            arrivalTime: new Date(employee.departureAt),
                                            language: employee.language,
                                        }))}
                                        rowHeight={35}
                                        checkboxSelection
                                        rowSelectionModel={registrationTokens}
                                        hideFooter={true}
                                        onRowSelectionModelChange={e => {
                                            const selectedToken: string[] = [];
                                            e.forEach(row => {
                                                selectedToken.push(row.toString());
                                            });
                                            setRegistrationTokens(selectedToken);
                                        }}
                                        localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
                                        unstable_headerFilters
                                    />
                                </ErrorBoundary>
                            </Stack>
                        ) : (
                            <Grid
                                container
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 'calc(100vh - 350px)',
                                    width: '100%',
                                }}>
                                <Grid item>
                                    <Typography variant='h6'>
                                        {loading ? (
                                            <Stack direction='row' spacing={2} alignItems='center'>
                                                <CircularProgress
                                                    sx={{
                                                        '--CircularProgress-size': '60px',
                                                    }}
                                                />
                                                <Typography variant='h6'>Adatok betöltése...</Typography>
                                            </Stack>
                                        ) : (
                                            'Nincs megjeleníthető adat!'
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </StyledDetailsCard>
                </Grid>
            </SecuredLayout>
        </LocalizationProvider>
    );
}
